/** @format */

import { Typography, Button, Grid } from "@mui/material";

import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";

function PageHeader({ Title }) {
  const user = {
    name: "Catherine Pike",
    avatar: "/static/images/avatars/1.jpg",
  };
  return (
    <Grid marginLeft={8.3}>
      <Grid item>
        <Typography variant='h3' component='h3' gutterBottom>
          {Title}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
