/** @format */
import {
  Avatar,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  UserDataProvider,
  useUserDataContext,
} from "src/hooks/userDataContext";

import { Item2 } from "./CustomeItem";
import { Item, Item1 } from "./CustomeItem/index";

export const PersonalDetails = ({ view, personalData, checkDoc, ShowBtn }) => {
  const { approvals, updateApprovals } = useUserDataContext();
  const itemsData = [
    {
      label: "Aadhar Card Front Image",
      logo: "https://m.economictimes.com/thumb/msid-59910353,width-1200,height-900,resizemode-4,imgsize-30651/how-to-enrol-your-children-for-aadhaar.jpg",
      src:
        personalData?.aadharFrontImagePreview ||
        "https://etvbharatimages.akamaized.net/etvbharat/prod-images/22-07-2023/1200-675-19065540-thumbnail-16x9-aadhar-aspera.jpg",
      approve: personalData?.isAadharFrontApproved || false,
      adminapprove: personalData?.adminApprovalStatus,
    },
    {
      label: "Aadhar Card Back Image",
      logo: "https://m.economictimes.com/thumb/msid-59910353,width-1200,height-900,resizemode-4,imgsize-30651/how-to-enrol-your-children-for-aadhaar.jpg",
      src:
        personalData?.aadharBackImagePreview ||
        "https://etvbharatimages.akamaized.net/etvbharat/prod-images/22-07-2023/1200-675-19065540-thumbnail-16x9-aadhar-aspera.jpg",
      approve: personalData?.isAadharBackApproved || false,
      adminapprove: personalData?.adminApprovalStatus,
    },
    {
      label: "Pancard",
      logo: "https://5.imimg.com/data5/SELLER/Default/2022/12/EV/SF/QX/91181334/unnamed-500x500.png",
      src:
        personalData?.panCardImagePreview ||
        "https://images.news18.com/ibnkhabar/uploads/2022/01/fake-PAN-card-164102564016x9.jpg",
      approve: personalData?.isPanCardApproved || false,
      adminapprove: personalData?.adminApprovalStatus,
    },
    {
      label: "Cancel Cheque",
      logo: "https://cdn2.vectorstock.com/i/1000x1000/78/51/money-cheque-icon-yellow-circle-frame-background-v-vector-20757851.jpg",
      src:
        personalData?.cancelChequeImagePreview ||
        "https://qph.cf2.quoracdn.net/main-qimg-34c77b7f8c6aa01f7aaf311996b869d9-pjlq",
      approve: personalData?.isCancelChequeApproved || false,
      adminapprove: personalData?.adminApprovalStatus,
    },
    {
      label: "SignatureImage",
      logo: "https://a.storyblok.com/f/191576/1200x800/b7ad4902a2/signature_maker_after_.webp",
      src:
        personalData?.signatureImagePreview ||
        "https://a.storyblok.com/f/191576/1200x800/b7ad4902a2/signature_maker_after_.webp",
      approve: personalData?.isSignatureApproved || false,
      adminapprove: personalData?.adminApprovalStatus,
    },
  ];
  const userdata =
    [
      { label: "Name", value: personalData?.name || "UNNAMED" },
      { label: "Email", value: personalData?.email },
      {
        label: "Date Of Birth",
        value: personalData?.dateOfBirth || "01-01-2000",
      },
      { label: "Phone", value: personalData?.phone },
    ] || [];

  const membershipPrice = personalData?.membershipAmount;

  let backgroundColor = "";
  if (membershipPrice <= 400) {
    backgroundColor = "#cca767";
  } else if (membershipPrice >= 400 && membershipPrice < 800) {
    backgroundColor = "#c69c54";
  } else {
    backgroundColor = "#bf9141";
  }
  const handleApprove = async (index) => {
    updateApprovals((prevApprovals) => ({
      ...prevApprovals,
      [index]: true,
    }));

    await checkDoc(index, true);
  };

  const handleReject = async (index) => {
    updateApprovals((prevApprovals) => ({
      ...prevApprovals,
      [index]: false,
    }));
    await checkDoc(index, false);
  };

  return (
    <>
      <Grid
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={"auto"}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid
          item
          xs={7.5}
          sm={7.5}
          md={7.5}
          lg={7.5}
          xl={7.5}
          sx={{ marginRight: { xs: 2.5, sm: 1, md: 2, lg: 2.5, xl: 2.5 } }}
        >
          <Item2>
            <TableContainer>
              <Box className='boxscroll'>
                <Typography variant='h3' ml={1} color={"white"}>
                  Personal Details
                </Typography>
              </Box>
              <Table
                sx={{
                  minWidth: 650,
                  padding: "50px",
                  "& tbody tr:last-child td": {
                    borderBottom: 0,
                  },
                }}
                aria-label='simple table'
              >
                <TableBody
                  style={{
                    height: "auto",
                    position: "sticky",
                  }}
                >
                  {userdata.map((detail, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant='h4' color={"rgb(0,0,0,0.4)"}>
                          {detail.label}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h4' color={"rgb(0,0,0,0.7)"}>
                          {detail.value}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={2}>
                      {itemsData?.map((item, index) => (
                        <Item key={index}>
                          <Avatar alt={item.label} src={item.logo} />
                          <Typography variant='h6' marginLeft={3}>
                            {item.label}
                          </Typography>
                          <Button
                            variant='outlined'
                            style={{
                              marginLeft: "auto",
                              marginRight: !item.adminapprove ? "0px" : "10px",
                            }}
                            onClick={() => {
                              view(item);
                            }}
                          >
                            View
                          </Button>

                          <>
                            {
                              <>
                                {ShowBtn === "true" && (
                                  <>
                                    {item.approve === true ? (
                                      <Box
                                        className='approvebox hoverEffect'
                                        sx={{ marginLeft: "10px" }}
                                      >
                                        <CheckCircleIcon
                                          onClick={() => handleApprove(index)}
                                          style={{
                                            color: "green",
                                            width: "30px",
                                            height: "30px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Box>
                                    ) : item.approve === false ? (
                                      <Box
                                        className='approvebox hoverEffect'
                                        sx={{ marginLeft: "10px" }}
                                      >
                                        <CheckCircleOutlineIcon
                                          onClick={() => handleApprove(index)}
                                          style={{
                                            color: "green",
                                            width: "30px",
                                            height: "30px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        className='approvebox hoverEffect'
                                        sx={{ marginLeft: "10px" }}
                                      >
                                        <CheckCircleOutlineIcon
                                          onClick={() => handleApprove(index)}
                                          style={{
                                            color: "green",
                                            width: "30px",
                                            height: "30px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Box>
                                    )}

                                    {item.approve === true ? (
                                      <Box className='approvebox hoverEffect'>
                                        <HighlightOffIcon
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleReject(index)}
                                        />
                                      </Box>
                                    ) : item.approve === false ? (
                                      <Box className='approvebox hoverEffect'>
                                        <CancelIcon
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleReject(index)}
                                        />
                                      </Box>
                                    ) : (
                                      <Box className='approvebox hoverEffect'>
                                        <HighlightOffIcon
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleReject(index)}
                                        />
                                      </Box>
                                    )}
                                  </>
                                )}
                              </>
                            }
                          </>
                        </Item>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Item2>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          sx={{ marginLeft: { xs: 2.5, sm: 1, md: 2, lg: 2.5, xl: 2.5 } }}
        >
          <Item1 style={{ backgroundColor, color: "black", height: "24rem" }}>
            <Typography variant='h1' gutterBottom>
              Membership
            </Typography>
            <Typography variant='h1' mt={2} mb={2}>
              Rs.{personalData?.membershipAmount}
            </Typography>
            <Typography
              mt={1}
              variant='h3'
              gutterBottom
              color={"rgb(0,0,0,0.8)"}
            >
              {personalData?.membershipType}
            </Typography>
          </Item1>
        </Grid>
      </Grid>
    </>
  );
};
