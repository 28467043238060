/** @format */

import { Lock } from "@mui/icons-material";
import {
  Box,
  Card,
  Container,
  Button,
  FormControl,
  TextField,
  Avatar,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { useState } from "react";

const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function Password({ handleChangePassword }) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#5569ff" }}>
          <Lock />
        </Avatar>
        <Typography component='h1' variant='h3' mb={3}>
          Change Password
        </Typography>
      </Box>
      <FormControl variant='outlined' fullWidth>
        <>
          <TextField
            margin='normal'
            required
            fullWidth
            name='newPassword'
            label='New Password'
            type='password'
            id='newPassword'
            autoComplete='new-password'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='confirmPassword'
            label='Confirm Password'
            type='password'
            id='confirmPassword'
            autoComplete='new-password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            onClick={handleChangePassword}
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            Continue
          </Button>
        </>
      </FormControl>
    </>
  );
}

export default Password;
