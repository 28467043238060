/** @format */

import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, Container, Grid, styled } from "@mui/material";

const PageTitle = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(4)};
        background:white !important;
`
);

interface PageTitleWrapperProps {
  children?: ReactNode;
}

const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children }) => {
  return (
    <Grid>
      <PageTitle className='MuiPageTitle-wrapper' sx={{ width: "100%" }}>
        <Container
          style={{
            margin: "0",
            padding: "0",
          }}
          maxWidth='lg'
        >
          {children}
        </Container>
      </PageTitle>
    </Grid>
  );
};

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitleWrapper;
