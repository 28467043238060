/** @format */

import { Box, Paper, styled } from "@mui/material";

export const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  marginTop: "15PX",
}));
export const Item1 = styled(Paper)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "55px",
  height: "16rem",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
  width: "100%",
}));
export const Item2 = styled(Paper)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "24rem",
  borderRadius: "0px 0px 10px 10px",
  width: "100%",
}));
export const Item3 = styled(Paper)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "18rem",
  borderRadius: "0px 0px 10px 10px",
  width: "100%",
}));
interface StyledBoxProps {
  searchQuery: any;
}

export const TableOverflow = styled(Box)<StyledBoxProps>(({ searchQuery }) => ({
  maxHeight: searchQuery ? "auto" : "54vh",
  overflow: "scroll",
  scrollBehavior: "smooth",
}));