/** @format */

import { useContext, useEffect, useState } from "react";

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Typography,
} from "@mui/material";
import { NavLink as RouterLink, Navigate, useNavigate } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";

import BrightnessLowTwoToneIcon from "@mui/icons-material/BrightnessLowTwoTone";

import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";

import DisplaySettingsTwoToneIcon from "@mui/icons-material/DisplaySettingsTwoTone";
import PagesIcon from "@mui/icons-material/Pages";
import { useUserDataContext } from "src/hooks/userDataContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { URL_CONFIG } from "src/config/urlConfig";
import { ToastContainer } from "react-toastify";
import { fetchAccountDetails, fetchCmsPagesData } from "src/content/api";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &:not(.Mui-children) {
            &.active,
            &:hover {
              background-color: ${alpha(
                theme.colors.alpha.trueWhite[100],
                0.06
              )};
              color: ${theme.colors.alpha.trueWhite[100]};

              .MuiButton-startIcon,
              .MuiButton-endIcon {
                color: ${theme.colors.alpha.trueWhite[100]};
              }
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  "transform",
                  "opacity",
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }`
);

function SidebarMenu() {
  const { closeSidebar, handleDropdownClick, isDropdownOpen } =
    useContext(SidebarContext);
  const navigate = useNavigate();
  const { cmsPages, updateCmsPages, updateAccount, isCreate }: any =
    useUserDataContext();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cmsPagesData = await fetchCmsPagesData();
        updateCmsPages(cmsPagesData);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);
  const adminId = localStorage.getItem("adminId");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accountData = await fetchAccountDetails(adminId);
        updateAccount(accountData);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, [isCreate]);
  return (
    <>
      <MenuWrapper>
        <List component='div'>
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/dashboard'
                  startIcon={<BrightnessLowTwoToneIcon />}
                >
                  Dashboard
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component='div'
          subheader={
            <ListSubheader component='div' disableSticky>
              Management
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/management/userdetails'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  User Details
                </Button>
              </ListItem>
            </List>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/management/appdata'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  App Data
                </Button>
              </ListItem>
            </List>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  onClick={closeSidebar}
                  component={RouterLink}
                  to='/management/mutualfunddata'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Mutual-Fund
                </Button>
              </ListItem>
            </List>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  onClick={handleDropdownClick}
                  startIcon={<PagesIcon />}
                  endIcon={
                    isDropdownOpen ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )
                  }
                >
                  CMS Pages
                </Button>
              </ListItem>
              {isDropdownOpen && (
                <>
                  <List component='div'>
                    {cmsPages.map((page) => (
                      <ListItem key={page.cmsPageId} component='div'>
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={() => {
                            closeSidebar();
                          }}
                          to={`/management/richtextbox/${page.cmsPageId}`}
                          startIcon={<PagesIcon />}
                          sx={{ marginLeft: "25px" }}
                        >
                          <Typography fontWeight={600}>{page.title}</Typography>
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </List>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/management/inquiry'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Inquiry
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component='div'
          subheader={
            <ListSubheader component='div' disableSticky>
              Accounts
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/management/profile/details'
                  startIcon={<AccountCircleTwoToneIcon />}
                >
                  User Profile
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
