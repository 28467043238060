import { useNavigate, useRoutes } from "react-router-dom";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import router from "./router";
import { UserDataProvider } from "./hooks/userDataContext";
import { useEffect } from "react";

function App() {
  const content = useRoutes(router);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);
  return (
    <UserDataProvider>
      <ThemeProvider>
        <CssBaseline />
        {content}
      </ThemeProvider>
    </UserDataProvider>
  );
}
export default App;
