/** @format */

import { Box, Container, Typography, styled } from "@mui/material";

const FooterWrapper = styled(Container)(
  ({ theme }) => `
    margin-top: auto;
    width: 95%;
    margin-left: 5%;
 
`
);

function Footer() {
    return (
      <FooterWrapper> 
      <Box
        pb={2}
        display={{ xs: "block", md: "flex", justifyContent: "flex-start" }}
        alignItems='center'
        justifyContent='space-between'
        flex={1}
      >
        <Typography variant='subtitle1'>
          &copy; 2024 - HWP Admin Dashboard
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
